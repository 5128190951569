import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';

export const addLicenseRequestForAndroidSubscription = async (input) => {
	try {
		const {
			data: { AddLicenseRequestForAndroidSubscription }
		} = await apollo.mutate({
			mutation: gql`
				mutation ADD_LICENSE_REQUEST_FOR_ANDROID_SUBSCRIPTION($licenseRequest: LicenseRequestInput!) {
					AddLicenseRequestForAndroidSubscription(NewLicenseRequest: $licenseRequest) {
						email
						xid
					}
				}
			`,
			variables: {
				licenseRequest: input
			}
		});

		return AddLicenseRequestForAndroidSubscription;
	} catch (e) {
		console.log(e.message);
	}
};

export const getLicenseRequest = async (xid) => {
	try {
		const query = gql`
			query GET_LICENSE_REQUEST($xid: String!) {
				GetLicenceRequest(LicenseRequestXid: $xid) {
					id
					email
					phone
					password
					licenseOwner
					projectTitle
					xid
					validated
					validatedOn
					language
					userId
					timeZone
					requestType
					userName
					userFirstName
					userTypeForFilm
					nextProductionDate
					budgetBracket
					emailValidationCode
					phoneValidationCode
					userTypeDesc
					languageDesc
				}
			}
		`;
		const {
			data: { GetLicenceRequest }
		} = await apollo.query({
			query,
			variables: {
				xid
			},
			fetchPolicy: 'no-cache'
		});

		return GetLicenceRequest;
	} catch (error) {
		console.log(error.message);
	}
};
