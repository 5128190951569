var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: `${
        _vm.showManageSubscription ? "" : "app flex-row align-items-center"
      }`,
    },
    [
      _vm.vueAppRunningProduction
        ? _c("div", { staticClass: "banner-testing-mode" })
        : _vm._e(),
      _vm.vueAppRunningProduction
        ? _c("div", { staticClass: "container-testing-mode" }, [
            _vm._v("\n\t\t" + _vm._s(_vm.FormMSG(268, "Testing")) + "\n\t"),
          ])
        : _vm._e(),
      _c("div", { staticClass: "container" }, [
        !_vm.forgotPassword && !_vm.showManageSubscription
          ? _c(
              "div",
              [
                _vm.showSuggestChrome
                  ? _c(
                      "b-row",
                      { staticClass: "justify-content-center" },
                      [
                        _c(
                          "b-col",
                          { attrs: { md: "8" } },
                          [
                            _c(
                              "b-alert",
                              {
                                attrs: {
                                  variant: "warning",
                                  show: "",
                                  dismissible: "",
                                },
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t" +
                                    _vm._s(_vm.FormMSG(250, "Use")) +
                                    " "
                                ),
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(_vm.FormMSG(251, "Chrome browser"))
                                  ),
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.FormMSG(252, "for better use")) +
                                    ".\n\t\t\t\t\t"
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "b-row",
                  { staticClass: "login justify-content-center" },
                  [
                    _c(
                      "b-col",
                      { staticClass: "form", attrs: { md: "8" } },
                      [
                        _c(
                          "b-card-group",
                          [
                            _c(
                              "b-card",
                              {
                                staticClass: "card-border-blue-light p-4",
                                class: `${
                                  !_vm.$screen.xl && !_vm.$screen.lg
                                    ? "card-full-fill"
                                    : "card-half-left-fill"
                                }`,
                                attrs: { "no-body": "" },
                              },
                              [
                                _c(
                                  "b-card-body",
                                  [
                                    _c("div", { staticClass: "text-center" }, [
                                      _c("img", {
                                        staticClass: "navbar-brand-full",
                                        attrs: {
                                          src: "img/brand/thegreenshot-brand.png",
                                          width: "225",
                                          alt: "TheGreenShot",
                                        },
                                      }),
                                      _c("h2", { staticClass: "pt-3 pb-2" }, [
                                        _vm._v(
                                          _vm._s(_vm.FormMSG(101, " Login"))
                                        ),
                                      ]),
                                    ]),
                                    _c(
                                      "b-input-group",
                                      { staticClass: "mb-3" },
                                      [
                                        _c(
                                          "b-input-group-prepend",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              [
                                                _c(_vm.getLucideIcon("User"), {
                                                  tag: "component",
                                                  attrs: {
                                                    color:
                                                      _vm.ICONS.PERCENT.color,
                                                    size: 18,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("b-form-input", {
                                          staticClass: "form-control",
                                          class: {
                                            "border-gray":
                                              _vm.$v.email.$error === false,
                                            "br-none":
                                              _vm.$v.email.$error === true,
                                          },
                                          attrs: {
                                            type: "email",
                                            disabled: _vm.askNewPassword,
                                            placeholder: _vm.FormMSG(
                                              103,
                                              "Email address"
                                            ),
                                            autocomplete: "username email",
                                            state: !_vm.$v.email.$error,
                                          },
                                          on: {
                                            keyup: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              return _vm.handleKeyUpEnterEmail.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.$v.email.$model,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.$v.email,
                                                "$model",
                                                $$v
                                              )
                                            },
                                            expression: "$v.email.$model",
                                          },
                                        }),
                                        _vm.$v.email.$error
                                          ? _c("b-input-group-append", [
                                              _c(
                                                "button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-tooltip",
                                                      rawName:
                                                        "v-b-tooltip.click.bottomleft",
                                                      value:
                                                        _vm.manageMessageEmailError(
                                                          _vm.$v.email
                                                        ),
                                                      expression:
                                                        "manageMessageEmailError($v.email)",
                                                      modifiers: {
                                                        click: true,
                                                        bottomleft: true,
                                                      },
                                                    },
                                                  ],
                                                  staticClass:
                                                    "btn-show-password border-red",
                                                  attrs: { type: "button" },
                                                },
                                                [
                                                  _c("AlertTriangle", {
                                                    attrs: {
                                                      color: "red",
                                                      size: 16,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ])
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm.askNewPassword
                                      ? _c("p", { staticClass: "text-muted" }, [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(
                                                  1111,
                                                  "New password"
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ])
                                      : _vm._e(),
                                    _c(
                                      "b-input-group",
                                      { staticClass: "mb-4" },
                                      [
                                        _c(
                                          "b-input-group-prepend",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              [
                                                _c(_vm.getLucideIcon("Lock"), {
                                                  tag: "component",
                                                  attrs: {
                                                    color:
                                                      _vm.ICONS.PERCENT.color,
                                                    size: 18,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("b-form-input", {
                                          staticClass: "form-control br-none",
                                          class: {
                                            "border-gray br-none":
                                              _vm.$v.password.$error === false,
                                          },
                                          attrs: {
                                            type: _vm.showPassword
                                              ? "text"
                                              : "password",
                                            placeholder: _vm.FormMSG(
                                              104,
                                              "Password"
                                            ),
                                            autocomplete: "current-password",
                                            state: !_vm.$v.password.$error,
                                          },
                                          on: {
                                            keyup: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              return _vm.handleKeyUpEnterPassword.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.$v.password.$model,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.$v.password,
                                                "$model",
                                                $$v
                                              )
                                            },
                                            expression: "$v.password.$model",
                                          },
                                        }),
                                        _c("b-input-group-append", [
                                          _c(
                                            "button",
                                            {
                                              staticClass: "btn-show-password",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.showPassword =
                                                    !_vm.showPassword
                                                },
                                              },
                                            },
                                            [
                                              !_vm.showPassword
                                                ? _c(_vm.getLucideIcon("Eye"), {
                                                    tag: "component",
                                                    attrs: {
                                                      color:
                                                        _vm.ICONS.PERCENT.color,
                                                      size: 18,
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm.showPassword
                                                ? _c(
                                                    _vm.getLucideIcon("EyeOff"),
                                                    {
                                                      tag: "component",
                                                      attrs: {
                                                        color:
                                                          _vm.ICONS.PERCENT
                                                            .color,
                                                        size: 18,
                                                      },
                                                    }
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _vm.$v.password.$error === true
                                            ? _c(
                                                "button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-tooltip",
                                                      rawName:
                                                        "v-b-tooltip.click.bottomleft",
                                                      value: _vm.FormMSG(
                                                        498,
                                                        "Password is required"
                                                      ),
                                                      expression:
                                                        "FormMSG(498, 'Password is required')",
                                                      modifiers: {
                                                        click: true,
                                                        bottomleft: true,
                                                      },
                                                    },
                                                  ],
                                                  staticClass:
                                                    "btn-show-password border-red",
                                                  staticStyle: {
                                                    "border-left":
                                                      "none !important",
                                                  },
                                                  attrs: { type: "button" },
                                                },
                                                [
                                                  _c("AlertTriangle", {
                                                    attrs: {
                                                      color: "red",
                                                      size: 16,
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _vm.askNewPassword
                                      ? _c(
                                          "div",
                                          [
                                            _vm.askNewPassword
                                              ? _c(
                                                  "p",
                                                  { staticClass: "text-muted" },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            1112,
                                                            "Password verification"
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "b-input-group",
                                              { staticClass: "mb-4" },
                                              [
                                                _c(
                                                  "b-input-group-prepend",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      [
                                                        _c(
                                                          _vm.getLucideIcon(
                                                            "Lock"
                                                          ),
                                                          {
                                                            tag: "component",
                                                            attrs: {
                                                              color:
                                                                _vm.ICONS
                                                                  .PERCENT
                                                                  .color,
                                                              size: 18,
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "form-control br-none",
                                                  attrs: {
                                                    type: _vm.showPasswordVerification
                                                      ? "text"
                                                      : "password",
                                                    placeholder: _vm.FormMSG(
                                                      1113,
                                                      "Re-enter Password"
                                                    ),
                                                    autocomplete:
                                                      "current-password",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.passwordVerification,
                                                    callback: function ($$v) {
                                                      _vm.passwordVerification =
                                                        $$v
                                                    },
                                                    expression:
                                                      "passwordVerification",
                                                  },
                                                }),
                                                _c("b-input-group-append", [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn-show-password",
                                                      attrs: { type: "button" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.showPasswordVerification =
                                                            !_vm.showPasswordVerification
                                                        },
                                                      },
                                                    },
                                                    [
                                                      !_vm.showPasswordVerification
                                                        ? _c(
                                                            _vm.getLucideIcon(
                                                              "Eye"
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS
                                                                    .PERCENT
                                                                    .color,
                                                                size: 18,
                                                              },
                                                            }
                                                          )
                                                        : _vm._e(),
                                                      _vm.showPasswordVerification
                                                        ? _c(
                                                            _vm.getLucideIcon(
                                                              "EyeOff"
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS
                                                                    .PERCENT
                                                                    .color,
                                                                size: 18,
                                                              },
                                                            }
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.askNewPassword && _vm.queryType.NU
                                      ? _c(
                                          "div",
                                          { staticClass: "mb-3" },
                                          [
                                            _c(
                                              "b-form-checkbox",
                                              {
                                                attrs: {
                                                  value: true,
                                                  "unchecked-value": false,
                                                },
                                                on: {
                                                  change:
                                                    _vm.handleChangeCheckTermsOfService,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        81,
                                                        "I accept"
                                                      )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "false-link",
                                                    on: {
                                                      click:
                                                        _vm.handleClickTermsOfService,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          82,
                                                          "TheGreenshot's Terms of Service"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          { attrs: { md: "12" } },
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                staticClass: "px-4",
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  type: "submit",
                                                  variant: "primary",
                                                  disabled:
                                                    _vm.loginDisabled() ||
                                                    _vm.waitProcessSignin,
                                                },
                                                on: { click: _vm.signin },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex justify-content-center align-items-center",
                                                  },
                                                  [
                                                    _vm.waitProcessSignin
                                                      ? _c("b-spinner", {
                                                          attrs: {
                                                            label: "Loading...",
                                                            small: "",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "pl-2",
                                                        staticStyle: {
                                                          "margin-top": "1px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              112,
                                                              "Login"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        !_vm.askNewPassword
                                          ? _c(
                                              "b-col",
                                              {
                                                staticClass: "text-right",
                                                attrs: { md: "12" },
                                              },
                                              [
                                                _c(
                                                  "b-button",
                                                  {
                                                    staticClass: "px-0",
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      variant: "link",
                                                      disabled:
                                                        _vm.waitProcessSignin,
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.handleClickForgotPassword,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            105,
                                                            "Forgot password?"
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-modal",
                      {
                        staticClass: "modal-success",
                        attrs: {
                          "header-class": "header-class-modal-doc-package",
                          "ok-variant": "success",
                          "ok-only": "",
                          title: _vm.FormMSG(108, "Success!"),
                        },
                        on: {
                          ok: function ($event) {
                            _vm.successModal = false
                          },
                        },
                        model: {
                          value: _vm.successModal,
                          callback: function ($$v) {
                            _vm.successModal = $$v
                          },
                          expression: "successModal",
                        },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(
                              _vm.FormMSG(
                                109,
                                "  An email has been sent to you. Open it and click on the provided link to reset your password."
                              )
                            ) +
                            "\n\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "b-modal",
                      {
                        staticClass: "modal-success",
                        attrs: {
                          "header-class": "header-class-modal-doc-package",
                          "ok-variant": "success",
                          "ok-only": "",
                          "no-close-on-backdrop": "",
                          "no-close-on-esc": "",
                          title: _vm.FormMSG(108, "Success!"),
                        },
                        on: {
                          ok: _vm.handleOkModalSuccessRegistration,
                          hidden: _vm.handleOkModalSuccessRegistration,
                        },
                        model: {
                          value: _vm.showModalSuccessRegistration,
                          callback: function ($$v) {
                            _vm.showModalSuccessRegistration = $$v
                          },
                          expression: "showModalSuccessRegistration",
                        },
                      },
                      [
                        _c("p", [
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(_vm.messageModalSuccessRegistration) +
                              "\n\t\t\t\t\t"
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "b-modal",
                      {
                        staticClass: "modal-danger",
                        attrs: {
                          "header-class": "header-class-modal-doc-package",
                          "ok-variant": "danger",
                          "ok-only": "",
                          "no-close-on-backdrop": "",
                          "no-close-on-esc": "",
                          title: _vm.FormMSG(140, "Subscription Error!"),
                        },
                        on: {
                          ok: function ($event) {
                            _vm.showSubscriptionNotOrder = false
                          },
                          hidden: function ($event) {
                            _vm.showSubscriptionNotOrder = false
                          },
                        },
                        model: {
                          value: _vm.showSubscriptionNotOrder,
                          callback: function ($$v) {
                            _vm.showSubscriptionNotOrder = $$v
                          },
                          expression: "showSubscriptionNotOrder",
                        },
                      },
                      [
                        _c("p", [
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(
                                _vm.FormMSG(
                                  141,
                                  "Your subscription is not in order, please call support to verify. All Solo+ projects are deactivated and your account is changed to Solo Free until your subscription returns to normal."
                                )
                              ) +
                              "\n\t\t\t\t\t"
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.forgotPassword
          ? _c(
              "div",
              [
                _c("account-recovery", {
                  on: {
                    "account-recovery:next-step":
                      _vm.handleAccountRecoveryNextStep,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }