<template>
	<b-row class="container justify-content-center">
		<b-col md="8">
			<b-card-group>
				<b-card no-body class="card-border-blue-light p-4">
					<b-card-body>
						<div class="text-center">
							<img class="navbar-brand-full" src="img/brand/thegreenshot-brand.png" width="225" alt="TheGreenShot" />
						</div>
						<h1 class="pt-4">{{ FormMSG(1, 'Account recovery') }}</h1>
						<p class="text-muted" style="margin-top: -6px">
							{{ FormMSG(2, "To protect your account, TheGreenShot wants to make sure it's you trying to log in") }}
						</p>
						<email-validation for-reset-password-user from-login @verification-email:check="handleVerificationEmailCheck" />
						<b-row>
							<b-col md="12">
								<b-button type="submit" variant="primary" class="px-4" block :disabled="!nextStep" @click="handleClickNextStep">
									{{ FormMSG(4, 'Pass to next step') }}
								</b-button>
							</b-col>
						</b-row>
					</b-card-body>
				</b-card>
			</b-card-group>
		</b-col>
	</b-row>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import EmailValidation from './EmailValidation';

export default {
	name: 'AccountRecovery',

	components: {
		EmailValidation
	},

	mixins: [languageMessages],

	data() {
		return {
			nextStep: false,
			xid: ''
		};
	},

	methods: {
		handleClickNextStep() {
			this.$emit('account-recovery:next-step', {
				xid: this.xid
			});
		},
		handleVerificationEmailCheck(payload) {
			this.nextStep = payload.emailVerified;
			this.xid = payload.xid;
		}
	}
};
</script>

<style scoped></style>
