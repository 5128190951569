var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "container justify-content-center" },
    [
      _c(
        "b-col",
        { attrs: { md: "8" } },
        [
          _c(
            "b-card-group",
            [
              _c(
                "b-card",
                {
                  staticClass: "card-border-blue-light p-4",
                  attrs: { "no-body": "" },
                },
                [
                  _c(
                    "b-card-body",
                    [
                      _c("div", { staticClass: "text-center" }, [
                        _c("img", {
                          staticClass: "navbar-brand-full",
                          attrs: {
                            src: "img/brand/thegreenshot-brand.png",
                            width: "225",
                            alt: "TheGreenShot",
                          },
                        }),
                      ]),
                      _c("h1", { staticClass: "pt-4" }, [
                        _vm._v(_vm._s(_vm.FormMSG(1, "Account recovery"))),
                      ]),
                      _c(
                        "p",
                        {
                          staticClass: "text-muted",
                          staticStyle: { "margin-top": "-6px" },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(
                                _vm.FormMSG(
                                  2,
                                  "To protect your account, TheGreenShot wants to make sure it's you trying to log in"
                                )
                              ) +
                              "\n\t\t\t\t\t"
                          ),
                        ]
                      ),
                      _c("email-validation", {
                        attrs: {
                          "for-reset-password-user": "",
                          "from-login": "",
                        },
                        on: {
                          "verification-email:check":
                            _vm.handleVerificationEmailCheck,
                        },
                      }),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "px-4",
                                  attrs: {
                                    type: "submit",
                                    variant: "primary",
                                    block: "",
                                    disabled: !_vm.nextStep,
                                  },
                                  on: { click: _vm.handleClickNextStep },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.FormMSG(4, "Pass to next step")
                                      ) +
                                      "\n\t\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }